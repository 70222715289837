import React from "react"
import Kunde from "../components/kunde"

import Seo from "../components/Seo"
const Kundenseite = () => {
  return (
    <>
      <Kunde />
    </>
  )
}

export default Kundenseite